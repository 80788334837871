import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';
import { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Features.css';
import local from './Features.module.css';

function Features() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    return (
        <div>
            <div id="features" className="menu_anchor"></div>
            <div className={['eightypercent', 'margin_top_40p', 'margin_bottom_40', ''].join(' ')}>
                <div className={['margin_bottom_40', 'margin_leftright_12'].join(' ')}>
                    <h1>Features</h1>
                    Maxim Money is designed from the ground up, changing the way you manage your finances, by reducing mouse clicks, and streamlining the way you work.
                </div>
                <Carousel interval={null} data-bs-theme="dark" activeIndex={index} onSelect={handleSelect}>
                    <Carousel.Item>
                        <Container fluid>
                            <Row>
                                <Col lg={6}>
                                    <div className={['margin_top_80', 'margin_bottom_80', ''].join(' ')}>
                                        <h2>Context-sensitive toolbars</h2>
                                        The main toolbar dynamically changes, depending what view you have selected in the main window.
                                        <div>&nbsp;</div>
                                        This keeps things simple by giving you the functions you need, but not cluttering up your view with options you don't
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className={[local.imgContainer, 'centre_cont', ''].join(' ')}>
                                        <div className={[local.imgDiv, 'centre', ''].join(' ')}>
                                            <figure>
                                                <img id={local.inline} src="toolbars.png" alt="Context-sensitive toolbars"></img>
                                            </figure>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Container fluid>
                            <Row>
                                <Col lg={6}>
                                    <div className={['margin_top_80', 'margin_bottom_80', ''].join(' ')}>
                                        <h2>In-line Reconciliation</h2>
                                        To ease the matching process, reconciliation is carried out directly within the main transaction register, automatically highlighting the most likely matches.
                                        <div>&nbsp;</div>
                                        This makes it easier to see what is new, and what is a duplicate.
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className={[local.imgContainer, 'centre_cont', ''].join(' ')}>
                                        <div className={[local.imgDiv, 'centre', ''].join(' ')}>
                                            <figure>
                                                <img id={local.inline} src="inline.png" alt="In-line Reconciliation"></img>
                                            </figure>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Container fluid>
                            <Row>
                                <Col lg={6}>
                                    <div className={['margin_top_80', 'margin_bottom_80', ''].join(' ')}>
                                        <h2>Flexible Layout</h2>
                                        The layout of windows within Maxim Money is completely flexible, allowing you to view multiple components simultaneously.
                                        <div>&nbsp;</div>
                                        For example, different account transaction registers side-by-side, with charts, calendar and portfolios arranged around them.
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className={[local.imgContainer, 'centre_cont', ''].join(' ')}>
                                        <div className={[local.imgDiv, 'centre', ''].join(' ')}>
                                            <figure>
                                                <img id={local.inline} src="layout.png" alt="Flexible Layout"></img>
                                            </figure>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Container fluid>
                            <Row>
                                <Col lg={6}>
                                    <div className={['margin_top_80', 'margin_bottom_80', ''].join(' ')}>
                                        <h2>Dark/Light modes</h2>
                                        You can select whether to display Maxim Money in Light or Dark mode to suit your preference, and this can be updated on the fly
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className={[local.imgContainer, 'centre_cont', ''].join(' ')}>
                                        <div className={[local.imgDiv, 'centre', ''].join(' ')}>
                                            <figure>
                                                <img id={local.inline} src="darklight.png" alt="Dark/Light modes"></img>
                                            </figure>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Container fluid>
                            <Row>
                                <Col lg={6}>
                                    <div className={['margin_top_80', 'margin_bottom_80', ''].join(' ')}>
                                        <h2>Transaction Groups</h2>
                                        You can group and split transactions, and see these directly within your transaction register
                                        <div>&nbsp;</div>
                                        This allows you to sub-categorise single payments, or group together payments from multiple payees
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className={[local.imgContainer, 'centre_cont', ''].join(' ')}>
                                        <div className={[local.imgDiv, 'centre', ''].join(' ')}>
                                            <figure>
                                                <img id={local.inline} src="groups.png" alt="Transaction Groups"></img>
                                            </figure>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Container fluid>
                            <Row>
                                <Col lg={6}>
                                    <div className={['margin_top_80', 'margin_bottom_80', ''].join(' ')}>
                                        <h2>Investment Portfolios</h2>
                                        You can add as many portfolios as you like, with clear breakdowns of price, value, cost and gain.
                                        <div>&nbsp;</div>
                                        Prices can be downloaded automatically from the internet, keeping your portfolio up-to-date.
                                        <div>&nbsp;</div>
                                        Both securities and crypto-currencies are supported.
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className={[local.imgContainer, 'centre_cont', ''].join(' ')}>
                                        <div className={[local.imgDiv, 'centre', ''].join(' ')}>
                                            <figure>
                                                <img id={local.inline} src="portfolio.png" alt="Investment Portfolios"></img>
                                            </figure>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Container fluid>
                            <Row>
                                <Col lg={6}>
                                    <div className={['margin_top_80', 'margin_bottom_80', ''].join(' ')}>
                                        <h2>Detailed Reporting</h2>
                                        Reporting is a breeze, with straightforward configuration
                                        <div>&nbsp;</div>
                                        Charts can be embedded in the reports, making them more attractive and easier to understand.                                        
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className={[local.imgContainer, 'centre_cont', ''].join(' ')}>
                                        <div className={[local.imgDiv, 'centre', ''].join(' ')}>
                                            <figure>
                                                <img id={local.inline} src="printpreview.png" alt="Print Preview"></img>
                                            </figure>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Carousel.Item>
                </Carousel>
            </div>
        </div>
    )
}

export default Features;