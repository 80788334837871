
function Footer() {
    return (
        <div className={[''].join(' ')}>
            <div id="footer" className="menu_anchor"></div>
            <div className="spacer"></div>
            <div className={['eightypercent', '', 'margin_bottom_40', 'footer', ''].join(' ')}>
                <div className="align_right">&copy; 2025 Wildfire Software Ltd</div>
            </div>
        </div>
    );
}

export default Footer;
