import React from 'react';
import './Future.module.css';


function Future() {
    return (
        <div className={[''].join(' ')}>
            <div id="future" className="menu_anchor"></div>
            <div className="spacer"></div>
            <div className={['eightypercent', 'margin_top_40p', 'margin_bottom_40p'].join(' ')}>
                <div className="margin_leftright_12">
                    <h1>Future Plans</h1>
                    Maxim Money is nearing its public release.  It is already a comprehensive application with many features.  These are some of the things it doesn't currently do, but we hope to implement soon:
                    <div>&nbsp;</div>
                    <ul>
                        <li>Bank Connectivity</li>
                        <li>Tags</li>
                        <li>Reminders</li>
                        <li>Downloading of prices/rates from custom sources</li>
                    </ul>
                </div>
            </div>
            &nbsp;
        </div>
    );
}

export default Future;