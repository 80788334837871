import React from 'react';
import './Landing.module.css';


function Quicken() {
    return (
        <div className={[''].join(' ')}>
            <div id="future" className="menu_anchor"></div>
            <div className="spacer"></div>
            <div className={['eightypercent', 'margin_top_40p', 'margin_bottom_40p'].join(' ')}>
                <div className="margin_leftright_12">
                    <h1>Quicken Alternative</h1>
                    Maxim Money offers a modern, fast alternative to Quicken, Intuit's legacy personal finance application.
                    <div>&nbsp;</div>
                    Quicken is targetted at the US market, so does not support integration with UK and EU banks.  Additionally, it uses a subscription model, locking users into paying a monthly or annual fee.
                    <div>&nbsp;</div>
                    Data from Quicken can be imported into Maxim Money, so you do not have to lose your history.  You can then continue with a modern, supported application which works under Windows 11 and beyond.
                </div>
            </div>
            &nbsp;
        </div>
    );
}

export default Quicken;
